import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';

@Injectable()
export class Globals {

  constructor() {
  }

  public stringify(val: any): string {
    if ((val === null) || typeof val === 'undefined') {
      return '';
    } else if (typeof val === 'string') {
      return val;
    } else if (typeof val === 'number') {
      return val.toString();
    } else {
      return JSON.stringify(val);
    }
  }

  public handleError(res: HttpErrorResponse | any) {
    console.error(res);
    let err = res.error;

    if (typeof err === 'string') {
      if (err.startsWith('<!DOCTYPE html>') === true) {
        let i1 = err.indexOf('<h1>');
        if (i1 > -1) {
          i1 += 4;
          const i2 = err.indexOf('</h1>', i1);
          err = err.substring(i1, i2);
        }
      } else {
        const i = err.indexOf('\n');
        if (i > -1)
          err = err.substring(0, i);
      }
    } else {
      if (res.statusText) {
        err = `${res.status} ${res.statusText}`;
      } else if (res.title) {
        err = `${res.status} ${res.title}`;
      } else {
        err = 'Server error';
      }
    }

    return observableThrowError(err);
  }

}
