<div *ngIf="distinctValues" class="ag-filter distinct-value-container" style="padding: 3px">
  <div class="ag-filter-body-wrapper">

    <div ref="ag-filter-loading" class="loading-filter ag-hidden">
      Loading...
    </div>

    <div class="ag-filter-header-container">
      <div *ngFor="let val of distinctValues" class="distinct-value-selector">
        <input type="checkbox" kendoCheckBox id="checkbox_{{val}}" [value]="val" (change)="onChecked($event)" />
        <label for="checkbox_{{val}}">{{ val }}</label>
      </div>
    </div>

  </div>
</div>
