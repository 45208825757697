import { Component } from "@angular/core";
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { IFilterAngularComp } from "ag-grid-angular";
import { FindValueSubscriber } from "rxjs/internal/operators/find";

@Component({
  selector: 'app-distinct-values-filter',
  templateUrl: './distinct-values-filter.component.html',
  styleUrls: ['./distinct-values-filter.component.scss']
})
export class DistinctValuesFilterComponent implements IFilterAngularComp {

  public filterParams: IFilterParams;
  public field: string;
  public distinctValues: string[];
  public selectedValues: string[];
  public hidePopup: Function;

  constructor() { }

  agInit(params: IFilterParams): void {
    this.filterParams = params;
    this.field = (params as any).field;
    this.distinctValues = [];
    this.selectedValues = [];

    this.filterParams.api.forEachNode((node) => {
      let val: string = node.data[this.field];
      if (!val) {
        val = "";
      }
      if (!this.distinctValues.includes(val)) {
        this.distinctValues.push(val);
      }
    });

    this.distinctValues.sort(function (a, b) {
      if (!a) return -1;
      else if (!b) return 1;
      else return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  }

  isFilterActive(): boolean {
    return true;
  }

  getModel(): any {
    return this.distinctValues;
  }

  setModel(model: any) {
    this.distinctValues = model;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    let val = params.data[this.field];
    if (!val) {
      val = "";
    }
    return (this.selectedValues && this.selectedValues.length > 0)
      ? this.selectedValues.includes(val)
      : true;
  }

  onFilterChanged() {
    this.filterParams.filterChangedCallback();
  }

  afterGuiAttached?(params?: { hidePopup?: Function }): void {
    this.hidePopup = params.hidePopup;
  }

  onChecked(evt: any): void {
    if (evt.target.checked) {
      this.selectedValues.push(evt.target.value);
    } else {
      const index: number = this.selectedValues.indexOf(evt.target.value);
      if (index > -1) {
        this.selectedValues.splice(index, 1);
      }  
    }
    this.onFilterChanged();
  }

}
