import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from "@angular/core";
import { Router } from '@angular/router';
import { AppService } from "src/app/services/app.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CatalogService } from "src/app/services/catalog.service";
import { MetadataService } from "src/app/services/metadata.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})

export class AppComponent implements OnInit {
  public year: number = new Date().getFullYear();
  public errorMessage: string = null;
  public statusMessage: string = null;

  public get showError(): boolean {
    return (this.errorMessage && this.errorMessage.length > 0);
  }

  public get showReload(): boolean {
    return (this.authenticationService.isLoggedIn && !this.catalogService.isLoading);
  }

  public get showExport(): boolean {
    return (this.authenticationService.isLoggedIn && !this.catalogService.isLoading);
  }

  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    public appService: AppService,
    public authenticationService: AuthenticationService,
    public catalogService: CatalogService,
    public metadataService: MetadataService,
  ) {
  }

  ngOnInit(): void {
    this.appService.errorMessageObservable$.subscribe((msg) => {
      this.errorMessage = msg;
    });

    this.appService.statusMessageObservable$.subscribe((msg) => {
      this.statusMessage = msg;
    });
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  toggleLogin(): void {
    if (this.authenticationService.isLoggedIn) {
      this.authenticationService.logout().then((logout) => {
        this.catalogService.catalog = null;
        this.router.navigate(['/login']);
      });
    } else {
      this.authenticationService.login().then((login) => {
        if (!(login && login.length)) {
          this.router.navigate(['/manager']);
        }
        else {
          this.appService.setErrorMessage(login);
        }
      });
    }
  }

  onReload(): void {
    this.loadCatalog(true);
  }

  onExport(): void {
    this.appService.exportCatalog();
  }

  closeError(): void {
    this.appService.setErrorMessage(null);
  }

  loadCatalog(reload: boolean): void {
    this.appService.loadCatalog(reload);
  }

}
