import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, shareReplay } from "rxjs/operators";
import { environment } from '../../environments/environment';
import { Globals } from "src/app/globals";
import { AppService } from "src/app/services/app.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CatalogInfo } from "src/app/models/catalog-info";
import { CatalogItem } from "src/app/models/catalog-item";

@Injectable()
export class CatalogService {

  public catalog: CatalogItem[] = null;
  public tags: string[] = null;
  public isLoading: boolean;

  private portalChunk: number = 100;
  private totalCalls: number = 0;
  private callsComplete: number = 0;

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private appService: AppService,
    private authenticationService: AuthenticationService,
  ) {
  }

  public async loadCatalog(reload: boolean, progressCallback: any): Promise<CatalogItem[]> {
    if (this.catalog && this.catalog.length && !reload) {
      return this.catalog;
    }

    this.catalog = null;
    this.isLoading = true;

    const catalogItems: CatalogItem[] = [];
    const catalogInfo: CatalogInfo = await this.getCatalogInfo();

    this.totalCalls = (catalogInfo.portalItemsCount / this.portalChunk) + (catalogInfo.connectionStrings.length * 5);
    this.callsComplete = 0;

    const databaseResult: any = await this.getDatabaseCatalogItems(catalogInfo, progressCallback);
    if (databaseResult.status === "OK") {
      this.appendCatalogItems(catalogItems, databaseResult.items);
      const portalResult: any = await this.getPortalCatalogItems(catalogInfo, progressCallback);
      if (portalResult.status === "OK") {
        this.appendCatalogItems(catalogItems, portalResult.items);
      } else {
        this.appService.setErrorMessage(`${portalResult.status}: ${portalResult.message}`);
      }
    } else {
      this.appService.setErrorMessage(`${databaseResult.status}: ${databaseResult.message}`);
    }

    this.catalog = catalogItems.sort(function (a, b) {
      let result = 0;

      if (a.category && !b.category) {
        result = -1;
      } else if (!a.category && b.category) {
        result = 1;
      } else if (a.category && b.category) {
        result = a.category.toLowerCase().localeCompare(b.category.toLowerCase());
      }

      if (result === 0) {
        if (a.title && !b.title) {
          result = 1;
        } else if (!a.title && b.title) {
          result = -1;
        } else if (a.title && b.title) {
          result = a.title.toLowerCase().localeCompare(b.title.toLowerCase());
        }
      }

      return result;
    });

    this.getCatalogTags();
    this.isLoading = false;

    return this.catalog;
  }

  private async getCatalogInfo(): Promise<CatalogInfo>
  {
    let catalogInfo: CatalogInfo = null;
    if (!this.authenticationService.isLoggedIn) {
      return catalogInfo;
    }

    const endpoint = `${environment.proxyUrl}GetCatalogInfo`;
    const params = new HttpParams()
      .append("subscription-key", environment.proxyKey)
      .append("token", this.authenticationService.token)
      ;

    return this.http
      .get<any>(endpoint, { params: params })
      .pipe(map(result => {
        if (result && result.status && result.status === "OK") {
          catalogInfo = result.catalogInfo;
        } else if (result) {
          this.appService.setErrorMessage(`${result.status}: ${result.message}`);
        } else {
          this.appService.setErrorMessage("The metadata catalog info is unavailable.");
        }        
        return catalogInfo;
      }),
        catchError(this.globals.handleError)
      )
      .toPromise()
      .catch((err) => {
        this.appService.setErrorMessage(`Error in GetCatalogInfo: ${err}`);
        return null;
      });
  }

  private async getPortalCatalogItems(catalogInfo: CatalogInfo, progressCallback: any): Promise<any> {
    let portalResult: any = {
      status: "OK",
      message: null,
      items: [],
    };

    let start: number = 1;
    const endpoint: string = `${environment.proxyUrl}GetCatalogItems`;

    while (start > -1) {
      const params = new HttpParams()
      .append("subscription-key", environment.proxyKey)
      .append("token", this.authenticationService.token)
      .append("source", "portal")
      .append("num", String(this.portalChunk))
      .append("start", String(start))
      ;

      const result: any = await this.http
      .get<any>(endpoint, { params: params })
      .pipe(
        map(items => {
          return items;
        }),
        shareReplay(),
        catchError(this.globals.handleError))
      .toPromise().then((items) => {
        return items;
      })
      .catch((err) => {
        return {
          status: "ERROR",
          message: `Error in GetCatalogItems: ${err}`,
        };
      });

      if (result && result.status && result.status === "OK") {
        start = result.nextStart;
        this.appendCatalogItems(portalResult.items, result.items);
      } else {
        portalResult.status = result.status;
        portalResult.message = result.message;
        break;
      }
  
      ++this.callsComplete;
      this.updateProgress(catalogInfo, progressCallback);
    }

    return portalResult;
  }

  private async getDatabaseCatalogItems(catalogInfo: CatalogInfo, progressCallback: any): Promise<any> {
    let databaseResult: any = {
      status: "OK",
      message: null,
      items: [],
    };

    if (catalogInfo.connectionStrings && catalogInfo.connectionStrings.length) {
      const endpoint = `${environment.proxyUrl}GetCatalogItems`;

      for (let i = 0; i < catalogInfo.connectionStrings.length; i++) {
        const params = new HttpParams()
        .append("subscription-key", environment.proxyKey)
        .append("token", this.authenticationService.token)
        .append("source", catalogInfo.connectionStrings[i])
        ;
  
        const result: any = await this.http
        .get<any>(endpoint, { params: params })
        .pipe(map(result => {
          return result;
        }),
          catchError(this.globals.handleError)
        )
        .toPromise()
        .catch((err) => {
          return {
            status: "ERROR",
            message: `Error in GetCatalogItems: ${err}`,
          };
        });

        if (result && result.status && result.status === "OK") {
          this.appendCatalogItems(databaseResult.items, result.items);
        } else {
          databaseResult.status = result.status;
          databaseResult.message = result.message;
          break;
        }

        this.callsComplete += 5;
        this.updateProgress(catalogInfo, progressCallback);
      }
    }

    return databaseResult;
  }

  private updateProgress(catalogInfo: CatalogInfo, progressCallback: any): void {
    if (progressCallback) {
      const percentComplete: number = Math.round((100 * this.callsComplete) / this.totalCalls);
      progressCallback(percentComplete);
    }
  }

  private getCatalogTags(): void {
    this.tags = [];

    if (this.catalog) {
      for (let i = 0; i < this.catalog.length; i++) {
        if (this.catalog[i].tags && this.catalog[i].tags.length) {
          for (let j = 0; j < this.catalog[i].tags.length; j++) {
            if (!this.tags.find(t => t.toLowerCase() === this.catalog[i].tags[j].toLowerCase())) {
              this.tags.push(this.catalog[i].tags[j]);
            }
          }
        }
      }

      this.tags.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    }
  }

  private appendCatalogItems(to: CatalogItem[], from: CatalogItem[]): void {
    if (from && from.length) {
      for (let i = 0; i < from.length; i++) {
        to.push(from[i]);
      }
    }
  }

}
