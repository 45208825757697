import { Component, Input, OnInit } from '@angular/core';
import { ChipRemoveEvent } from '@progress/kendo-angular-buttons';
import { Metadata } from 'src/app/models/metadata';
import { CatalogService } from 'src/app/services/catalog.service';

@Component({
  selector: 'app-keyword-picker',
  templateUrl: './keyword-picker.component.html',
  styleUrls: ['./keyword-picker.component.scss']
})
export class KeywordPickerComponent implements OnInit {
  @Input() metadata: Metadata;
  
  public filter: string;

  public source: Array<{ text: string; value: number }> = [];

  public data: Array<{ text: string; value: number }>;

  constructor(private catalogService: CatalogService) { 
   }

  ngOnInit(): void {
    this.refreshChips();
  }

  settoDirty(){
    this.metadata.formGroup.markAsDirty();
  }

  refreshChips() {
    this.data = null;
    if (this.catalogService.tags && this.catalogService.tags.length) {
      this.catalogService.tags.forEach( (value, i) => {
        this.source.push({
          text: value,
          value: i + 1,
        });
      });
      this.data = this.source.slice(0);
    }
  }

  public addNew(): void {
    this.source.push({
      text: this.filter,
      value: 0,
    });
    this.handleFilter(this.filter);
  }

  public onRemove(e: any) {
    const index = this.metadata.keywords.indexOf(e.sender.label);
    if (index > -1) {
      this.metadata.keywords.splice(index, 1);
    }
    this.refreshChips();
    this.settoDirty();
  }

  public valueChange(value: any) {
    if (!this.metadata.keywords.includes(value.text)) {
      this.metadata.keywords.push(value.text);
      this.settoDirty();
    }
  }

  public handleFilter(value: any) {
    this.filter = value;
    this.data = this.source.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

}
