import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { GridOptions } from "@ag-grid-community/core";
import { Metadata } from 'src/app/models/metadata';

@Component({
  selector: 'app-data-elements',
  templateUrl: './data-elements.component.html',
  styleUrls: ['./data-elements.component.scss']
})
export class DataElementsComponent implements OnInit {
  @Input() isReady: boolean;
  @Input() metadata: Metadata;

  public gridOptions: GridOptions;
  
  constructor() { }

  ngOnInit(): void {
    this.initGrid();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let change = changes['metadata'];

    if (!change.firstChange) {
      this.initGrid();
    }
  }

  initGrid(): void {
    this.gridOptions = {
     
      suppressCellFocus: true,
      context: {
        parentComponent: this
      },
      
      defaultColDef: {
        headerClass: 'metadata-header',
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
    };

    this.gridOptions.columnDefs = [
      
      {
        field: "alias",
        headerName: "Alias",
      },
      {
        field: "label",
        headerName: "Label",
      },
      {
        field: "notes",
        headerName: "Notes",
      },
      {
        field: "precision",
        headerName: "Precision",
      },
      {
        field: "scale",
        headerName: "Scale",
      },
     
      {
        field: "type",
        headerName: "Type",
      },
      {
        field: "width",
        headerName: "Width",
      },
     
    
    ];
  }


  onGridReady(evt: any): void {
    evt.api.sizeColumnsToFit();
  }

}
