import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Metadata } from 'src/app/models/metadata';
import {
  Code,
  CharacterSetCodes,
  TopicCategoryCodes,
  ProgressCodes,
  ScopeCodes,
  MaintenanceFrequencyCodes,
  ContentStatusCodes,
} from 'src/app/models/codes';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  @Input() isReady: boolean;
  @Input() metadata: Metadata;

  selectedMetaData: Metadata;
  language: any = null;
  scopeLevel: any = null;
  characterSet: any = null;
  topicCategory: any = null;
  referenceSystem: any = null;
  metadataLastUpdatedDate: any = null;
  metadataLastUpdatedTime: any = null;
  metadataUpdatedTime: any = null;
  metadataUpdatedBy: any = null;

  public characterSetCodes: Code[] = CharacterSetCodes;
  public topicCategoryCodes: Code[] = TopicCategoryCodes;
  public progressCodes: Code[] = ProgressCodes;
  public maintenanceFrequencyCodes: Code[] = MaintenanceFrequencyCodes;
  public contentStatusCodes: Code[] = ContentStatusCodes;

  constructor() {}

  convertDate(dateStr: string) {
    try {
      let strYear = dateStr.substring(0, 4);
      let strMonth = dateStr.substring(4, 6);
      let strDay = dateStr.substring(6, 8);

      return new Date(strMonth + '/' + strDay + '/' + strYear);
    } catch {
      return null;
    }
  }

  updateImage(base64Str: string) {
    if (base64Str){
      this.metadata.thumbnail = base64Str.substring(base64Str.indexOf(",") + 1);
    } else {
      this.metadata.thumbnail = null;
    }
  }

  isDate(dateStr: any) {
    return !isNaN(new Date(dateStr).getDate());
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.language = this.metadata.formGroup.get('language').value;
    
    let scopeLevelCode = ScopeCodes.find(c => (c.key === this.metadata.formGroup.controls["scopeLevel"].value));
    let characterSetCode = CharacterSetCodes.find(c => (c.key === this.metadata.formGroup.controls["characterSet"].value));
    let topicCategoryCode = TopicCategoryCodes.find(c => (c.key === this.metadata.formGroup.controls["topicCategory"].value));
   
    this.scopeLevel = (scopeLevelCode ? scopeLevelCode.value : null);
    this.characterSet = (characterSetCode ? characterSetCode.value : null);
    this.topicCategory = (topicCategoryCode ? topicCategoryCode.value : null);

    this.referenceSystem = this.metadata.formGroup.get('projection').value;
    this.metadataLastUpdatedTime = this.metadata.formGroup.get('metadataLastUpdatedTime').value;
    this.metadataLastUpdatedDate = this.metadata.formGroup.get('metadataLastUpdatedDate').value;
  }

}
