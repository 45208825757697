import { Component, OnInit } from "@angular/core";
import { LabelSettings } from "@progress/kendo-angular-progressbar";
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: "app-loading-spinner",
  templateUrl: "./loading-spinner.component.html",
  styleUrls: ["./loading-spinner.component.scss"]
})
export class LoadingSpinnerComponent implements OnInit {
  public loading: boolean;
  public message: string;
  public progress: number;
  public label: LabelSettings = {
    format: "percent",
    position: "center",
  };

  constructor(private loaderService: LoaderService) {
    this.loaderService.loading.subscribe((loading) => {
      this.loading = loading;
    });

    this.loaderService.message.subscribe((message) => {
      this.message = message;
    });

    this.loaderService.progress.subscribe((progress) => {
      this.progress = progress;
    });
  }

  ngOnInit() {
  }

}
