import { Component, OnInit, HostListener } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MetadataService } from 'src/app/services/metadata.service';
import { Metadata } from 'src/app/models/metadata';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import { FileRestrictions } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {

  public showImportSelected: boolean = false;
  public xmlData: any = null;
  public statusMessage: string = null;
  public metadata: Metadata = null;
  public subLayers: string[] = [];
  public isReady: boolean = false;

  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.xml'],
  };

  public get isLoaded(): boolean {
    return this.metadata != null;
  }

  public get title(): string {
    return this.metadata
      ? this.metadata.formGroup.controls['citation'].value
      : null;
  }

  constructor(
    public appService: AppService,
    private loaderService: LoaderService,
    private metadataService: MetadataService
  ) {
    this.appService.loadMetadataObservable$.subscribe(() => {
      if (this.appService.showDetails) {
        this.loadMetadata();
      }
    });
  }

  loadMetadata() {
    this.loaderService.loading.next(true);
    this.showImportSelected = false;
    this.statusMessage = null;

    this.metadataService
    .loadMetadataForItem(this.appService.id, this.appService.type, this.appService.source)
    .then((result) => {
      this.metadata = result.metadata;
      this.subLayers = result.subLayers;
      this.isReady = true;
      this.loaderService.loading.next(false);
    });
  }

  ngOnInit(): void {}

  canDeactivate(): boolean {
    return (this.metadata && this.metadata.formGroup.dirty)
      ? confirm("WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to discard them.")
      : true;
  }

  backToCatalog = (): void => {
    if (this.canDeactivate()) {
      this.metadata = null;
      this.isReady = false;
      this.appService.toggleView("catalog");
    }
  }

  onSave = (): void => {
    this.metadataService.saveEsriMetadataXml(this.metadata).then((result) => {
      if (result) {
        this.statusMessage = `The metadata for '${this.title}' has been saved.`;
      }
    });
  };

  onExportEsri = (): void => {
    this.saveFile(this.metadata.esriXml);
  };

  onExportIso = (): void => {
    this.saveFile(this.metadata.isoXml);
  };

  saveFile(inXml: string) {
    const blob = new Blob([inXml], { type: 'text/plain;' });
    saveAs(
      blob,
      `${this.title ? this.title : 'CDDOT-Metadata-Manager-Export'}.xml`
    );
  }

  importFile = (): void => {
    const title = this.metadata.formGroup.controls['citation'].value;
    this.metadata.parseXml(this.xmlData);

    if (title && title.length) {
      // Don't overwrite the title on import.
      this.metadata.formGroup.controls['citation'].setValue(title);
    }

    this.statusMessage = `The metadata for '${this.title}' has been imported.`;
  };

  selectFile = (e: any) => {
    this.showImportSelected = false;

    if (e.files[0]) {
      const ext = e.files[0].extension;
      this.showImportSelected = ext.toUpperCase().includes('XML');

      let fileReader = new FileReader();
      fileReader.readAsText(e.files[0].rawFile);
      fileReader.onload = () => {
        this.xmlData = fileReader.result;
      };
    }
  };

  setStatus = (message: string) => {
    this.statusMessage = message;
  };

  loadSubLayer = (subLayer: string) => {
    if (subLayer && subLayer.length) {
      const i1: number = subLayer.lastIndexOf('(');
      const i2: number = subLayer.indexOf(')', i1);

      if (i1 > -1 && i2 > -1) {
        const id: string = subLayer.substring(i1 + 1, i2);

        if (this.appService.type === 'Feature Layer') {
          this.appService.source = this.appService.source.substring(0, this.appService.source.lastIndexOf('/'));
        }

        this.appService.source = `${this.appService.source}/${id}`;
        this.appService.type = 'Feature Layer';
        this.loadMetadata();
      }
    } else if (this.appService.type === 'Feature Layer') {
      this.appService.type = 'Feature Service';
      this.appService.source = this.appService.source.substring(0, this.appService.source.lastIndexOf('/'));
      this.loadMetadata();
    }
  };

}
