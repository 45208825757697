<div class="row justify-content-center" style="margin-top:20px">
  <div class="col-11">
    <kendo-tabstrip #tabstrip>
      <kendo-tabstrip-tab title="Overview" [selected]="true">
        <ng-template kendoTabContent>
          <div class="content">
           <app-overview [metadata]="metadata" [isReady]="isReady" *ngIf="metadata != null"></app-overview>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Stewards">
        <ng-template kendoTabContent>
          <div class="content">
            <app-stewards [metadata]="metadata" [isReady]="isReady" *ngIf="metadata != null"></app-stewards>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Data Elements" *ngIf="showDataElements">
        <ng-template kendoTabContent>
          <div class="content">
            <app-data-elements [metadata]="metadata" [isReady]="isReady" *ngIf="metadata != null"></app-data-elements>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>