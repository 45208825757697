// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  /* local 
  proxyUrl: "http://localhost:7071/api/",
  proxyKey: "",
  */

  /* dev 
  proxyUrl: "https://vhbdevctdotatlasapis.azure-api.net/metadata/",
  proxyKey: "11cc5d1920304133aa33675abd906a53",
  */

  /* staging 
  proxyUrl: "https://enterpriseapi-stg.dot.ct.gov/metadata/",
  proxyKey: "5e84e01d3aa0422e835b805e77fdf309",
  */

  /* production */
  proxyUrl: "https://enterpriseapi.dot.ct.gov/metadata/",
  proxyKey: "fa82c28b4e92420c96ce1464e7d84e96",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
