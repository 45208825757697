import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { AppService } from 'src/app/services/app.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private appService: AppService,
    private router: Router,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const init: string = await this.authService.initialize();
    if (init && init.length) {
      this.appService.setErrorMessage(init);
      return false;
    }

    const signin: string = await this.authService.checkSignin(true);
    if (signin && signin.length) {
      this.appService.setErrorMessage(signin);
      return false;
    }

    return true;
  }
}
