<form [formGroup]="metadata.formGroup">
  <fieldset class="k-form-fieldset">
    <div class="row">
      <div class="col-7">

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="title"
                text="Title"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="citation"
                [clearButton]="true"
                [readonly]="!metadata.canEdit"
                #title
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="standardName"
                text="Standard name"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="standardName"
                [clearButton]="true"
                [readonly]="!metadata.canEdit"
                #standardName
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="standardVersion"
                text="Standard version"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="standardVersion"
                [clearButton]="true"
                [readonly]="!metadata.canEdit"
                #standardVersion
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="status"
                text="Status"
                class="form-title"
              ></kendo-label>

              <kendo-combobox
                [allowCustom]="false"
                formControlName="dataStatus"
                [data]="progressCodes"
                textField="value"
                valueField="key"
                [valuePrimitive]="true"
                [readonly]="!metadata.canEdit"
                #status
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="topicCategory"
                text="Topic Category"
                class="form-title"
              ></kendo-label>

              <kendo-combobox
                [allowCustom]="false"
                [data]="topicCategoryCodes"
                textField="value"
                valueField="key"
                [valuePrimitive]="true"
                formControlName="topicCategory"
                [readonly]="!metadata.canEdit"
                #topicCategory
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>

          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="dataUpdateFrequency"
                text="Data update frequency"
                class="form-title"
              ></kendo-label>

              <kendo-combobox
                [allowCustom]="false"
                [data]="maintenanceFrequencyCodes"
                textField="value"
                valueField="key"
                [valuePrimitive]="true"
                formControlName="dataUpdateFrequency"
                [readonly]="!metadata.canEdit"
                #dataUpdateFrequency
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="creationDate"
                text="Created date"
                class="form-title"
              ></kendo-label>

              <kendo-datepicker
                [readonly]="!metadata.canEdit"
                formControlName="creationDate"
                #creationDate
              ></kendo-datepicker>
            </kendo-formfield>
          </div>

          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="publicationDate"
                text="Publication date"
                class="form-title"
              ></kendo-label>

              <kendo-datepicker
                [readonly]="!metadata.canEdit"
                formControlName="publicationDate"
                #publicationDate
              ></kendo-datepicker>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="revisionDate"
                text="Revision date"
                class="form-title"
              ></kendo-label>

              <kendo-datepicker
                [readonly]="!metadata.canEdit"
                formControlName="revisionDate"
                #revisionDate
              ></kendo-datepicker>
            </kendo-formfield>
          </div>

          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="revisedBy"
                text="Revised by"
                class="form-title"
              ></kendo-label>

              <kendo-textbox
                formControlName="revisedBy"
                [clearButton]="true"
                [readonly]="!metadata.canEdit"
                #revisedBy
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row" style="border-top: 1px solid #eee">
          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="northExtent"
                text="Geographic Extent"
                class="form-title"
              ></kendo-label>

              <kendo-textbox
                formControlName="northExtent"
                [clearButton]="true"
                [readonly]="!metadata.canEdit"
                #northExtent
              ></kendo-textbox>
              <kendo-formhint>North</kendo-formhint>
            </kendo-formfield>
          </div>

          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-textbox
                formControlName="southExtent"
                [clearButton]="true"
                [style.marginTop.px]="20"
                [readonly]="!metadata.canEdit"
                #southExtent
              ></kendo-textbox>
              <kendo-formhint>South</kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-textbox
                formControlName="eastExtent"
                [clearButton]="true"
                [style.marginTop.px]="20"
                [readonly]="!metadata.canEdit"
                #eastExtent
              ></kendo-textbox>
              <kendo-formhint>East</kendo-formhint>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-textbox
                formControlName="westExtent"
                [clearButton]="true"
                [style.marginTop.px]="20"
                [readonly]="!metadata.canEdit"
                #westExtent
              ></kendo-textbox>
              <kendo-formhint>West</kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3" style="border-top: 1px solid #eee">
          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="temporalExtentStartDate"
                text="Temporal Extent"
                class="form-title"
              ></kendo-label>

              <kendo-datepicker
                [readonly]="!metadata.canEdit"
                formControlName="temporalExtentStartDate"
                #temporalExtentStartDate
              ></kendo-datepicker>
              <kendo-formhint>Start date</kendo-formhint>
            </kendo-formfield>
          </div>

          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-datepicker
                [style.marginTop.px]="20"
                [readonly]="!metadata.canEdit"
                formControlName="temporalExtentEndDate"
                #temporalExtentEndDate
              ></kendo-datepicker>
              <kendo-formhint>End date</kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3" style="border-top: 1px solid #eee">
          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="metadataUpdateFrequency"
                text="Metadata update frequency"
                class="form-title"
              ></kendo-label>

              <kendo-combobox
                [allowCustom]="false"
                [data]="maintenanceFrequencyCodes"
                textField="value"
                valueField="key"
                [valuePrimitive]="true"
                formControlName="metadataUpdateFrequency"
                [readonly]="!metadata.canEdit"
                #metadataUpdateFrequency
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>

          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="metadataCreatedDate"
                text="Metadata created date"
                class="form-title"
              ></kendo-label>

              <kendo-datepicker
                [readonly]="!metadata.canEdit"
                formControlName="metadataCreatedDate"
                format="MM/dd/yyyy"
                #metadataCreatedDate
              ></kendo-datepicker>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="contentStatus"
                text="Content Status"
                class="form-title"
              ></kendo-label>

              <kendo-combobox
                [allowCustom]="false"
                [data]="contentStatusCodes"
                textField="value"
                valueField="key"
                [valuePrimitive]="true"
                formControlName="contentStatus"
                [readonly]="!metadata.canEdit"
                #contentStatus
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>

          <div class="col-6 pt-3">
            <kendo-formfield>
              <kendo-label
                [for]="metadataNextUpdateDate"
                text="Metadata next update date"
                class="form-title"
              ></kendo-label>

              <kendo-datepicker
                [readonly]="!metadata.canEdit"
                formControlName="metadataNextUpdateDate"
                format="MM/dd/yyyy"
                #metadataNextUpdateDate
              ></kendo-datepicker>
            </kendo-formfield>
          </div>
        </div>
        <!---->

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="abstract"
                text="Abstract"
                class="form-title"
              ></kendo-label>
              <kendo-editor
                [style.height.px]="160"
                [iframe]="false"
                [readonly]="!metadata.canEdit"
                formControlName="abstract"
                #abstract
              ></kendo-editor>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="useConstraints"
                text="Use constraint"
                class="form-title"
              ></kendo-label>

              <kendo-editor
                [style.height.px]="160"
                [iframe]="false"
                [readonly]="!metadata.canEdit"
                formControlName="useConstraints"
                #useConstraints
              ></kendo-editor>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="purpose"
                text="Purpose"
                class="form-title"
              ></kendo-label>
              <kendo-textarea
                formControlName="purpose"
                [rows]="5"
                [readonly]="!metadata.canEdit"
                #purpose
              >
              </kendo-textarea>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="credits"
                text="Credits"
                class="form-title"
              ></kendo-label>
              <kendo-textarea
                formControlName="credits"
                [rows]="5"
                [readonly]="!metadata.canEdit"
                #credits
              >
              </kendo-textarea>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="lineageStatement"
                text="Lineage statement"
                class="form-title"
              ></kendo-label>
              <kendo-textarea
                formControlName="lineageStatement"
                [rows]="5"
                [readonly]="!metadata.canEdit"
                #lineageStatement
              >
              </kendo-textarea>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="processDescription"
                text="Process description"
                class="form-title"
              ></kendo-label>
              <kendo-textarea
                formControlName="processDescription"
                [rows]="5"
                [readonly]="!metadata.canEdit"
                #processDescription
              >
              </kendo-textarea>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="sourceDescription"
                text="Source description"
                class="form-title"
              ></kendo-label>
              <kendo-textarea
                formControlName="sourceDescription"
                [rows]="5"
                [readonly]="!metadata.canEdit"
                #sourceDescription
              >
              </kendo-textarea>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="maintenanceNotes"
                text="Maintenance notes"
                class="form-title"
              ></kendo-label>
              <kendo-textarea
                formControlName="maintenanceNotes"
                [rows]="5"
                [readonly]="!metadata.canEdit"
                #maintenanceNotes
              >
              </kendo-textarea>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-12">
            <kendo-formfield>
              <kendo-label
                [for]="onlineLinks"
                text="Online links"
                class="form-title"
              ></kendo-label>
              <kendo-textarea
                formControlName="onlineLinks"
                [rows]="5"
                [readonly]="!metadata.canEdit"
                #onlineLinks
              >
              </kendo-textarea>
            </kendo-formfield>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div class="row">
          <div class="col-12">
            <div
              style="
                background-color: #eee;
                padding: 15px;
                border: 1px solid #ddd;
                word-wrap: break-word;
              "
            >
              <div class="row">
                <div class="col-4">Type</div>
                <div class="col-8">{{ metadata.layerType }}</div>
              </div>

              <div class="row">
                <div class="col-4">Id</div>
                <div class="col-8">{{ metadata?.itemId }}</div>
              </div>

              <div class="row">
                <div class="col-4">Source</div>
                <div class="col-8">{{ metadata?.source }}</div>
              </div>

              <div class="row">
                <div class="col-4">Spatial feature type</div>
                <div class="col-8"></div>
              </div>

              <div class="row">
                <div class="col-4">Reference system</div>
                <div class="col-8">{{ referenceSystem }}</div>
              </div>

              <div class="row">
                <div class="col-4">Language</div>
                <div class="col-8">{{ language }}</div>
              </div>

              <div class="row">
                <div class="col-4">Character set</div>
                <div class="col-8">{{ characterSet }}</div>
              </div>

              <div class="row">
                <div class="col-4">Scope level</div>
                <div class="col-8">{{ scopeLevel }}</div>
              </div>

              <div class="row">
                <div class="col-4">Metadata last update by</div>
                <div class="col-8">{{ metadataUpdatedBy }}</div>
              </div>

              <div class="row">
                <div class="col-4">Metadata last updated</div>
                <div class="col-8">
                  {{ metadataLastUpdatedDate | date: "MM/dd/yyyy" }} -
                  {{ metadataLastUpdatedTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <app-keyword-picker [metadata]="metadata"></app-keyword-picker>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <app-image-picker
              [metadata]="metadata"
              (updateImage)="updateImage($event)"
            ></app-image-picker>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</form>
