import { Component, OnInit, Input, SimpleChanges, ViewChild } from '@angular/core';
import { LayoutModule, TabStripComponent } from "@progress/kendo-angular-layout";
import { Metadata } from 'src/app/models/metadata';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {
  @ViewChild('tabstrip') public tabstrip: TabStripComponent;
  @Input() metadata: Metadata;
  @Input() appService: AppService
  @Input() isReady: boolean;

  showDataElements: boolean = false;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let change = changes['metadata'];

    if (change && !change.firstChange && change.currentValue && change.currentValue.dataElements) {
      this.showDataElements = change.currentValue.dataElements.length > 0;   
    }
   
    this.appService.showDetailsObservable$.subscribe(() => {
      if (this.tabstrip) {
        this.tabstrip.selectTab(0);
      }
    });
  }

}
