<div *ngIf="catalogService.catalog" style="width: 100%; height: 100%">
  <ag-grid-angular
   #grid
   class="ag-theme-balham"
   style="width: 100%; height: 100%"
   [rowData]="catalogService.catalog"
   [gridOptions]="gridOptions"
   (gridReady)="onGridReady($event)"
   (selectionChanged)="onSelectionChanged($event)"
   (filterChanged)="onFilterChanged($event)"
   >
 </ag-grid-angular>
</div>
