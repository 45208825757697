import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AgGridModule } from '@ag-grid-community/angular';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from "@progress/kendo-angular-editor";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";
import { Globals } from './globals';
import { AppService } from './services/app.service';
import { AuthenticationService } from './services/authentication.service';
import { CatalogService } from './services/catalog.service';
import { MetadataService } from './services/metadata.service';
import { LoaderService } from './services/loader.service';
import { KeywordPickerComponent } from './components/details/content/keyword-picker/keyword-picker.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { CatalogComponent } from './components/catalog/catalog.component';
import { DistinctValuesFilterComponent } from './components/distinct-values-filter/distinct-values-filter.component';
import { AuthGuard } from './services/auth-guard.service';
import { DetailsComponent } from './components/details/details.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/details/header/header.component';
import { ContentComponent } from './components/details/content/content.component';
import { OverviewComponent } from './components/details/content/overview/overview.component';
import { StewardsComponent } from './components/details/content/stewards/stewards.component';
import { DataElementsComponent } from './components/details/content/data-elements/data-elements.component';
import { ImagePickerComponent } from './components/details/content/image-picker/image-picker.component';
import { ManagerComponent } from './components/manager/manager.component';

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

@NgModule({
  declarations: [
    AppComponent,
    LoadingSpinnerComponent,
    DistinctValuesFilterComponent,
    CatalogComponent,
    DetailsComponent,
    LoginComponent,
    HeaderComponent,
    ContentComponent,
    OverviewComponent,
    StewardsComponent,
    DataElementsComponent,
    KeywordPickerComponent,
    ImagePickerComponent,
    ManagerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AgGridModule,
    DialogsModule,
    ButtonsModule,
    LayoutModule,
    LabelModule,
    InputsModule,
    ReactiveFormsModule,
    FormsModule,
    DropDownsModule,
    DateInputsModule,
    EditorModule,
    UploadsModule,
    ProgressBarModule,
  ],
  providers: [
    AuthGuard,
    Globals,
    AppService,
    AuthenticationService,
    CatalogService,
    MetadataService,
    LoaderService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
