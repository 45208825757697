import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { Metadata } from 'src/app/models/metadata';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges{
  @Input() isLoaded: boolean;
  @Input() backToCatalog: Function;
  @Input() onSave: Function;
  @Input() onImport: Function;
  @Input() onExportEsri: Function;
  @Input() onExportIso: Function;
  @Input() fileRestrictions: FileRestrictions;
  @Input() selectFile: Function;
  @Input() importFile: Function;
  @Input() setStatus: Function;
  @Input() xmlData: string;
  @Input() metadata: Metadata;
  @Input() readonly: boolean;
  @Input() showImportSelected: boolean;
  @Input() statusMessage: string;
  @Input() subLayers: string[] = [];

  @Output() loadSubLayer: EventEmitter<string> = new EventEmitter<string>();

  public importToggle: boolean = false;
  public importButtonToggle: boolean = true;
  public headerTitle: string = '';
  public headerSubtitle: string = '';
  public enableFileSelect: boolean = false;

  constructor(public appService: AppService) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const metadataChange = changes['metadata'];
    if (metadataChange) {
      this.headerSubtitle = '';
      this.headerTitle = '';
      this.importToggle = false;
  
      if (!metadataChange.firstChange && metadataChange.currentValue) {
        let frmGroup = metadataChange.currentValue.formGroup;
        let citation = frmGroup.controls['citation'].value;
        let layerType = metadataChange.currentValue.layerType;
        if (citation) this.headerTitle = citation;
        if (layerType) this.headerSubtitle += ' (' + layerType + ')';
      }
    }

    const subLayersChange = changes['subLayers'];
    if (subLayersChange && subLayersChange.firstChange) {
      this.appService.selectedSublayer = undefined;
    }
  }

  checkAllowImport = (e: any) => {
    if (e.files[0]) {this.enableFileSelect = e.files[0].extension.toUpperCase().includes('XML');}
  };

  toggleImport() {
    this.importToggle = !this.importToggle;
    if (this.importToggle) {
      this.setStatus(null);
    }
  }

  subLayerSelectionChange(evt: any): void {
    this.appService.selectedSublayer = evt;
    this.loadSubLayer.emit(evt);
  }

}
