<div class="progress-loader" [hidden]="!loading">
  <div class="loading-spinner">
    <div>
      <div>
        <img src="assets/loading.gif" />
      </div>
      <div *ngIf="progress != null">
        <kendo-progressbar [label]="label" [value]="progress"></kendo-progressbar>
      </div>
      <div *ngIf="message && message.length">
        <span class="loading-message">{{ message }}</span>
      </div>
    </div>
  </div>
</div>