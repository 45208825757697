import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Metadata } from 'src/app/models/metadata';

@Component({
  selector: 'app-stewards',
  templateUrl: './stewards.component.html',
  styleUrls: ['./stewards.component.scss']
})
export class StewardsComponent implements OnInit {
  @Input() isReady: boolean;
  @Input() metadata: Metadata;
  public phoneMask = "(999) 000-0000";
  inputWidthSmall: number = 250;
  inputWidth: number = 250;
  selectedWidth: number = 250;
  selectedWidthSmall: number = 250;
  htmlEditorHeight: number = 150;
  htmlEditorWidth: number = 800;
  selectedMetaData: Metadata;
  textEditorWidth: number = 800;
  textEditorHeight: number = 300;

  constructor() { }

  ngOnInit(): void {
  }

}
